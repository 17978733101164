import { Select, SelectProps } from '@chakra-ui/react';
import React from 'react';

const brands = [
  '10 Punts',
  '3 Corações',
  '40 Paises',
  '4 D',
  '56º Aniversario',
  'A Borboleta',
  'A Brasileira',
  'A Caféeira',
  'A Cascata',
  'Adfa',
  'Adria Cafes',
  'Alfredo',
  'Ana Aeroportos de Portugal',
  'Arabo',
  'Armazém do Caffé',
  'Aromas Café',
  'Astoria',
  'Atlas',
  'Avia',
  'Banco Pinto & Sotto Mayor',
  'Bar Europa',
  'Barletta Caffé',
  'Bazar',
  'Bazzara',
  'Befa Caffe',
  'Beira Douro',
  'Bellissimo',
  'Bi',
  'Biblos',
  'Bicafé',
  'Bogani',
  'Bombeiros Vila do Conde',
  '(Boneco)',
  'Bou',
  'Bracafé',
  'Brancoffee',
  'Branquinha Pastelaria',
  'Brasilia',
  'Brasilian',
  'Bricelta',
  'Bricleta',
  'Bristol',
  'Brunatti',
  'Buondi',
  'Cabanas',
  'Café 3Jp',
  'Café Alberto',
  'Café Café',
  'Café Caiçara',
  'Café Centro',
  'Cafe Crem',
  'Café do Ponto',
  'Café Dourado',
  'Café Gama',
  'Café Gelo ',
  'Café Luso-Brasileiro',
  'Café Portobello',
  'Café Reneka',
  'Cafés Boca',
  'Cafés Carrion',
  'Cafes El Criolo',
  'Cafés Mambo',
  'Cafes Nilo',
  'Cafes Pont',
  'Cafes Richard',
  'Cafes Ros',
  'Cafés Santa Maria',
  'Cafés Tchanqué',
  'Cafes Valiente',
  'Cafetaria Europa',
  'Caffe Barbera',
  'Caffe Capoccetti',
  'Caffecel',
  'Caffe Diemme',
  'Caffé Diemme',
  'Caffe Nannini',
  'Caffe Si',
  'Caffe Vergnano',
  'Caffe Vero',
  'Camelo',
  'Candelas',
  'Canelli',
  'Caravela',
  'Casa del Caffé',
  'Casa de Sarmento',
  'C & C',
  'Ccafe',
  'Cervejaria Brasão',
  'Charm',
  "Chave D'Ouro",
  'Ci',
  'Classic Coffee',
  'Coffee Heavan',
  'Colonial',
  'Cordesa',
  'Costa',
  'Costadoro',
  'Cotor Cafes',
  'Crem Caffe',
  'Cristina',
  'Cubana',
  'Cubano',
  'Cup & Cino',
  'Dallas Grill',
  'Dallmayr',
  'Danesi',
  'D-E',
  'Delta',
  'Delta Q',
  'Devotion',
  'Dibarcafe',
  'Disney Paris',
  'Divenzio Giuseppe',
  'Doce Miragem',
  'Dubois',
  'Emas Coffee',
  'Espresso',
  'Esse Café',
  'Esse Caffe',
  'Essentia',
  'Europa Parlament',
  'Fão',
  'Feb',
  'Ferreira Castro',
  "Fidely'S",
  'Fifa',
  'Filicori',
  'Filosofia Q',
  'Flor do Minho',
  'Flunch',
  'Franck',
  'Franja',
  'Fratelli',
  'Garibaldi',
  'Geresino',
  'Germer',
  'Gilbert',
  'Giorno',
  'Gold Kava ',
  'Gometero',
  'Goriziana Caffe',
  'Gran Salvador Dal 1966',
  "Grão D'Africa",
  "Grão D'Ouro",
  'Guglielmo',
  'Hardy',
  'Hausbrandt',
  'Havaneza Cafes',
  'Heredia',
  'Hochland',
  'Hospitais Senhor do Bonfim',
  'Hoteles Solans',
  'Il Caffe Di Roma',
  'Il Gondolero',
  'Illy',
  'Il Mio',
  'I Love',
  'Imperios Cafes',
  'Incas',
  'Indy',
  'I-O',
  'Ionia',
  'Ipac',
  'Ipaq',
  'Iti Caffe',
  'Izzo Caffé',
  'Jacobs',
  'Jeronymo',
  'Joe',
  'Johan Caffe',
  'Johnson Diversey',
  'Káaps',
  'Kaffa',
  'Kibueno',
  'Kilatyno',
  'Kimbo',
  'La Cantina de David',
  'La Flor del Café',
  'La Garza',
  'La Grilladiére',
  'La San Marco',
  'La Sureña ',
  'Latinocafe',
  'Latino Café',
  'Lavazza',
  'Lendário',
  'Lions Clube da Maia',
  'Lisboa Caffé',
  'Lucaffe',
  'Lusitania',
  'Mafari Café',
  'Malongo',
  'Mambo',
  'Manuel',
  'Marcilla',
  'Masalto',
  'Mascaf',
  'Maxis',
  'M Battista',
  'Mc Café',
  'Mécafé',
  'Meltino',
  'Mesquita',
  'Mike',
  'Moak',
  'Mocay',
  'Mokafe',
  'Mokambo',
  'Mokaor',
  'Mokarabia',
  'Momento Perfeito',
  'Montanha Restaurante',
  'Mugg & Bean',
  'Musetti',
  'Nandi',
  'Negrão',
  'Negrita Cafes',
  'Nescafé',
  'Nespresso',
  'Nicola',
  'Nou Hostalet',
  'Nova Casa dos Leitões',
  'Novell',
  'Novo Dia Cafes',
  'Ocean Basket',
  'O Marinheiro',
  'One Lounge',
  'Orem Caffe',
  'Orfeo',
  'Origem',
  'Palmeira',
  'Pantir',
  'Paraiso do Liz',
  'Passalacqua',
  'Passo Fundo',
  'Piazza',
  'Plantaciones',
  'Plazza',
  'Pomodoro',
  'Pont',
  'Portela',
  'Portioli',
  'Portofino Café',
  'Portugália',
  'Povoa de Varzim',
  'Puerto del Café',
  'Quatro Cafes',
  'Ramecel',
  'Ravezzi',
  'Real Coffee',
  'Rendilheiras Portugal',
  'Restaurante Rei dos Leitões',
  'Restaurantes Inácio Meira',
  'Restaurante Varzim',
  'Richard',
  'Rio Bravo',
  'Ritazza',
  'Roca Mora',
  'Romano',
  'Rombouts',
  'Rovi',
  'Sabores Pevidém',
  'Sandy',
  'Santa Maria',
  'Santa Monica',
  'Santana Hotel',
  'Sanzala',
  'Saula',
  'Schari Coffee',
  'Segafredo',
  'Seriporto',
  'Sibaritas',
  'Siboney',
  'Sical',
  'Siesta Cafes',
  'Silveira',
  'Silver Caffe',
  'Solar de Criaz',
  'Sotocal',
  'Sporting',
  'Spur Steak Ranches',
  'Star Coffee',
  'Storia del Caffe',
  'Storie Caffe',
  'Stracto',
  'Taberna do Centenario',
  'Tagalos',
  'Tap',
  'Tasquinha da Lina',
  'Tenco',
  'Teodosio Restaurante',
  'The Traveller',
  'Tofa',
  'Tonino Lamborghini',
  'Tony',
  'Torrefazione (Remasca)',
  'Torrié',
  'Trismoka',
  'Tropical',
  'Tupinamba',
  'Tuscani Caffe',
  'Universal',
  'Utam',
  'Valentino',
  'Viriato',
  'Wenzo Café',
];

const BrandSelect = React.forwardRef<HTMLSelectElement, SelectProps>(
  (props, ref) => (
    <Select {...props} ref={ref}>
      {brands.map((brand) => (
        <option key={brand} value={brand}>
          {brand}
        </option>
      ))}
    </Select>
  )
);

export default BrandSelect;
