import { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Stack,
  useBreakpointValue,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import {
  useAuthenticationStatus,
  useNhostClient,
  useUserData,
} from '@nhost/react';
import { useNavigate, generatePath, useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  useListCupsQuery,
  useDeleteCupByIdMutation,
  useInsertCupMutation,
} from '../utils/__generated__/graphql';

import DebouncedSearchInput from '../components/common/DebouncedSearchInput';
import { Cup } from '../components/cups/cup';
import AddCupDrawer from '../components/cups/AddCupDrawer';
import CupCardSkeleton from '../components/cups/CupCard/CupCardSkeleton';
import CupCard from '../components/cups/CupCard/CupCard';
import ImageCarousel from '../components/image_carousel/ImageCarousel';

import EditCupPage, { Route as EditCupRoute } from './EditCup';

const Cups: React.FC<{ itemsPerPage?: number }> = ({ itemsPerPage = 20 }) => {
  const params = useParams();
  const userData = useUserData();
  const isAdmin = (userData?.roles || []).includes('adminUser');

  const getPaginationOptions = useCallback(
    (page: number) => {
      return {
        limit: itemsPerPage,
        offset: (page - 1) * itemsPerPage,
      };
    },
    [itemsPerPage]
  );
  const [search, setSearch] = useState('');

  const navigate = useNavigate();
  const { isAuthenticated } = useAuthenticationStatus();
  const nhost = useNhostClient();

  const [pageInfo, setPageInfo] = useState<{
    dataLength: number;
    hasMore: boolean;
    page: number;
    total: number;
  }>({
    dataLength: 0,
    hasMore: false,
    page: 1,
    total: 0,
  });

  const {
    data: cupsData,
    error: cupsQueryError,
    fetchMore,
    refetch,
    client: cupsClient,
  } = useListCupsQuery({
    variables: {
      brandSearch: search,
      ...getPaginationOptions(pageInfo.page),
    },
    skip: !isAuthenticated,
  });

  // start- insert cup
  const [insertCup, { data: createdCupData, loading: insertingCup }] =
    useInsertCupMutation({
      context:
        (isAdmin && {
          headers: {
            'X-Hasura-Role': 'adminChavenas',
          },
        }) ||
        undefined,
    });

  const handleCreateCup = useCallback(
    async (brand?: string) => {
      if (brand) {
        await insertCup({
          variables: {
            brand,
          },
        });
      }
    },
    [insertCup]
  );

  useEffect(() => {
    // redirect if the user created a new Cup
    if (!insertingCup && createdCupData?.insertCup?.id)
      navigate(
        generatePath(EditCupRoute, { cupId: createdCupData.insertCup.id })
      );
  }, [navigate, insertingCup, createdCupData?.insertCup?.id]);
  // end - insert cup

  // start - delete cup
  const [deleteCupById] = useDeleteCupByIdMutation({
    context:
      (isAdmin && {
        headers: {
          'X-Hasura-Role': 'adminChavenas',
        },
      }) ||
      undefined,
  });

  const handleDeleteCup = async (cupId: string) => {
    await deleteCupById({
      variables: {
        id: cupId,
      },
    });
    await refetch();
  };
  // end - delete cup

  const [selectedCup, setSelectedCup] = useState<Cup | null>(null);

  const fetchNextPage = useCallback(
    async (search = '', reset?: boolean) => {
      if (!isAuthenticated) return;
      if (reset) cupsClient.clearStore();

      setPageInfo((prevPageInfo) => {
        const page = reset === true ? 1 : prevPageInfo.page + 1;
        const total = reset === true ? 0 : prevPageInfo.total;
        const dataLength = reset === true ? 0 : prevPageInfo.dataLength;
        const hasMore = reset === true ? false : prevPageInfo.hasMore;

        const queryOptions = {
          variables: {
            brandSearch: search,
            ...getPaginationOptions(page),
          },
        };
        fetchMore(queryOptions);

        return {
          dataLength,
          hasMore,
          total,
          page,
        };
      });
    },
    [isAuthenticated, fetchMore, getPaginationOptions]
  );

  useEffect(() => {
    if (cupsQueryError) {
      console.error(
        `The following error ocurred while retrieving the cups List:`,
        cupsQueryError
      );
      return;
    }
    if (!cupsData) return;

    const dataLength = cupsData.cups?.length || 0;
    if (pageInfo.dataLength === dataLength) return;

    const total = cupsData.cups_aggregate?.aggregate?.count || 0;
    setPageInfo({
      dataLength,
      hasMore: pageInfo.page < Math.floor(total / itemsPerPage),
      page: pageInfo.page,
      total,
    });
  }, [
    cupsQueryError,
    cupsData,
    itemsPerPage,
    pageInfo.dataLength,
    pageInfo.page,
  ]);

  const handleSearchChange = useCallback(
    (newSearchValue: string) => {
      if (search !== newSearchValue) {
        setSearch(newSearchValue);
        fetchNextPage(newSearchValue, true);
      }
    },
    [search, fetchNextPage]
  );

  const cupId = params['cupId'];
  useEffect(() => {
    if (!!cupId && !isAdmin) {
      navigate(Route, { replace: true });
    }
  }, [navigate, cupId, isAdmin]);

  const responsiveCupsConfig = useBreakpointValue({
    base: { gap: 32, width: '2xs' },
    sm: { gap: 32, width: 'sm' },
    md: { gap: 32, width: '2xl' },
    lg: { gap: 32, width: '2xl' },
    xl: { gap: 32, width: '5xl' },
  });

  const responsiveCupCardConfig = useBreakpointValue({
    base: { maxWidth: '2xs', height: '2xs' },
    sm: { maxWidth: 'sm', height: 'xs' },
    md: { maxWidth: 'xs', height: '2xs' },
    lg: { maxWidth: 'xs', height: '2xs' },
    xl: { maxWidth: 'xs', height: '2xs' },
  });
  const renderCupItem = (cup: Cup) => (
    <CupCard
      key={cup.id}
      maxWidth={responsiveCupCardConfig?.maxWidth}
      width={responsiveCupCardConfig?.maxWidth}
      imageHeight={responsiveCupCardConfig?.height}
      data={{
        cup,
        isNew: true,
      }}
      onClick={(clickedCup) => {
        setSelectedCup(clickedCup);
      }}
      contextMenu={
        (isAdmin && {
          editCup: {
            label: 'Edit Cup',
            onClick: () =>
              navigate(generatePath(EditCupRoute, { cupId: cup.id })),
          },
          deleteCup: {
            label: 'Delete Cup',
            onClick: () => {
              handleDeleteCup(cup.id);
            },
          },
        }) ||
        null
      }
    />
  );

  return (
    <>
      <Stack
        spacing={10}
        width={(responsiveCupsConfig && responsiveCupsConfig.width) || ''}
        margin={'0 auto'}
        pb={10}
      >
        <HStack
          pt={10}
          bgColor={'white'}
          pos={'fixed'}
          w={(responsiveCupsConfig && responsiveCupsConfig.width) || ''}
          zIndex={100}
          pb={'8px'}
        >
          <DebouncedSearchInput
            handleDebouncedValueChange={(brandSearchValue) =>
              handleSearchChange(brandSearchValue)
            }
            placeholder="Pesquisar por marcas"
          />
          {isAdmin && (
            <AddCupDrawer
              onSubmit={(brand) => handleCreateCup(brand)}
              isAddingCup={insertingCup}
            />
          )}
        </HStack>
        <Box pt={50}>
          {(cupsData?.cups.length && (
            <InfiniteScroll
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: responsiveCupsConfig?.gap || 10,
                alignItems: 'flex-start',
              }}
              dataLength={cupsData.cups.length} //This is important field to render the next data
              next={() => fetchNextPage(search)}
              hasMore={pageInfo.hasMore}
              loader={<CupCardSkeleton />}
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }
            >
              {Cup.fromCupsQuery(cupsData.cups).map((cup: Cup) =>
                renderCupItem(cup)
              )}
            </InfiniteScroll>
          )) || (
            <p style={{ textAlign: 'center' }}>
              <b>No results found!</b>
            </p>
          )}
        </Box>
      </Stack>
      <Modal
        onClose={() => {
          setSelectedCup(null);
        }}
        size={'full'}
        isOpen={!!selectedCup}
      >
        <ModalOverlay />
        <ModalContent borderRadius={0}>
          <ModalCloseButton zIndex={100} />
          <ModalBody>
            <ImageCarousel
              imageHeight={'calc(100vh - 24px)'}
              images={(selectedCup?.images || []).map((cupImage) => {
                return {
                  id: cupImage.id,
                  url: cupImage.fileId
                    ? nhost.storage.getPublicUrl({ fileId: cupImage.fileId }) +
                      '?w=800'
                    : '',
                };
              })}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        onClose={() => {
          navigate(Route);
        }}
        size={'full'}
        isOpen={!!params['cupId'] && isAdmin}
      >
        <ModalOverlay />
        <ModalContent borderRadius={0}>
          <ModalCloseButton zIndex={100} />
          <ModalBody>
            <EditCupPage />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Cups;

export const Route = '/cups';
