import { useEffect } from 'react';
import { useSignOut, useAuthenticated } from '@nhost/react';

import { Container, Flex, Heading, Spinner, Stack } from '@chakra-ui/react';
import DefaultCupImageIcon from '../components/cups/DefaultCupImageIcon';

const SignOut = () => {
  const isAuthenticated = useAuthenticated();
  const { signOut } = useSignOut();

  useEffect(() => {
    if (isAuthenticated) {
      setTimeout(() => signOut(), 1000);
    }
  });

  return (
    <Container maxW={'md'} height={'calc(100vh - 120px)'} display={'flex'}>
      <Flex align={'center'} justify={'center'}>
        <Stack spacing={8} mx={'auto'} py={12} px={6} width={'md'}>
          <Stack align={'center'} spacing={10}>
            <DefaultCupImageIcon />
            <Heading fontSize={'4xl'} pb={10}>
              Good bye...
            </Heading>
            <Spinner />
          </Stack>
        </Stack>
      </Flex>
    </Container>
  );
};

export default SignOut;

export const Route = '/sign-out';
