import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider, theme } from '@chakra-ui/react';
import { NhostReactProvider } from '@nhost/react';
import { NhostApolloProvider } from '@nhost/react-apollo';

import Routes from './Routes';

import { nhost } from './utils/nhost';
import { InMemoryCache } from '@apollo/client';
import { offsetLimitPagination } from '@apollo/client/utilities';

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        cups: offsetLimitPagination(),
      },
    },
  },
});

export const App = () => (
  <BrowserRouter>
    <NhostReactProvider nhost={nhost}>
      <NhostApolloProvider nhost={nhost} cache={cache} connectToDevTools={true}>
        <ChakraProvider theme={theme}>
          <Routes />
        </ChakraProvider>
      </NhostApolloProvider>
    </NhostReactProvider>
  </BrowserRouter>
);
