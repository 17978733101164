import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Center, useColorModeValue, Icon } from '@chakra-ui/react';
import { RiImageAddLine } from 'react-icons/ri';

interface DropzoneOptions {
  onFileAccepted: (acceptedFile: File) => void;
  maxFiles?: number;
  // value in px
  iconSize?: number;
}

export default function Dropzone({
  onFileAccepted,
  maxFiles,
  iconSize = 20,
}: DropzoneOptions) {
  const onDrop = useCallback(
    (acceptedFiles: File[]) =>
      acceptedFiles.forEach((acceptedFile) => onFileAccepted(acceptedFile)),
    [onFileAccepted]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'image/*': [] },
    maxFiles: maxFiles,
    multiple: maxFiles !== 1,
  });

  const activeBg = useColorModeValue('blackAlpha.100', 'blackAlpha.600');
  const borderColor = useColorModeValue(
    isDragActive ? 'grey.300' : 'blackAlpha.300',
    isDragActive ? 'grey.500' : 'blackAlpha.500'
  );

  return (
    <Center
      p={10}
      cursor="pointer"
      bg={isDragActive ? activeBg : 'transparent'}
      _hover={{ bg: activeBg }}
      transition="background-color 0.2s ease"
      borderRadius={4}
      border="3px dashed"
      borderColor={borderColor}
      height="100%"
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Icon
        as={RiImageAddLine}
        mr={2}
        boxSize={`${iconSize}px`}
        color="blackAlpha.700"
      />
    </Center>
  );
}
