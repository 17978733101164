import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Spinner,
  Link,
} from '@chakra-ui/react';
import { ErrorPayload } from '@nhost/core';
import { useNhostClient } from '@nhost/react';
import { useCallback, useState } from 'react';
import { Route as SignUpRoute } from '../../pages/SignUp';

export default function SignInCard() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [signInError, setSignInError] = useState<ErrorPayload | null>(null);
  const nhost = useNhostClient();
  const translationEn = [
    {
      code: 'unverified-user',
      msg: 'You must verify your email to sign in. Check your mailbox and follow the instructions to verify your email.',
    },
  ];

  const getErrorMessage = (error: ErrorPayload) => {
    const errorTranslation = translationEn.find(
      (trnsltn) => trnsltn.code === error.error
    );
    return (
      (errorTranslation && errorTranslation.msg) ||
      `${error.message} (${error.error})`
    );
  };

  const onClickCallback = useCallback(() => {
    const signIn = async (data: { email: string; password: string }) => {
      setIsLoading(true);
      const { error } = await nhost.auth.signIn(data);
      setIsLoading(false);
      if (error) {
        setSignInError(error);
      }
    };
    signIn({ email, password });
  }, [email, password, nhost.auth]);

  return (
    <Flex
      minH={'100vh'}
      align={'center'}
      justify={'center'}
      bg={useColorModeValue('gray.50', 'gray.800')}
    >
      <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'}>Sign in to your account</Heading>
          <Text fontSize={'lg'} color={'gray.600'}>
            ✌️
          </Text>
        </Stack>
        <Box
          rounded={'lg'}
          bg={useColorModeValue('white', 'gray.700')}
          boxShadow={'lg'}
          p={8}
        >
          <Stack spacing={4}>
            <FormControl id="email">
              <FormLabel>Email address</FormLabel>
              <Input type="email" onChange={(e) => setEmail(e.target.value)} />
            </FormControl>
            <FormControl id="password">
              <FormLabel>Password</FormLabel>
              <Input
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormControl>
            <Stack spacing={10} pt={2}>
              <Button
                bg={'blue.400'}
                color={'white'}
                _hover={{
                  bg: 'blue.500',
                }}
                onClick={onClickCallback}
              >
                Sign in
              </Button>
            </Stack>
            <Stack pt={6}>
              <Text align={'center'}>
                Don't have a Login?{' '}
                <Link color={'blue.400'} href={SignUpRoute}>
                  Sign Up
                </Link>
              </Text>
            </Stack>
            {isLoading && <Spinner />}
            {signInError && (
              <Text color={'red.600'}>{getErrorMessage(signInError)}</Text>
            )}
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}
