import { Box, Container, Heading } from '@chakra-ui/react';
import { useAuthenticationStatus, useNhostClient } from '@nhost/react';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { ICupImage, ICupDetails, Cup } from '../components/cups/cup';
import {
  useDeleteCupImageByIdMutation,
  useEditCupDetailsMutation,
  useGetCupByIdQuery,
  useInsertCupImageMutation,
  useSwitchDefaultCupImageMutation,
  useSetDefaultCupImageMutation,
} from '../utils/__generated__/graphql';
import CupDetailsForm from '../components/cups/CupDetailsForm';
import CupImageList from '../components/cups/CupImageList';
import Capture360 from '../components/cups/Capture360';

const EditCupPage: React.FC = () => {
  const nhost = useNhostClient();
  const params = useParams();
  const { isAuthenticated } = useAuthenticationStatus();
  const {
    data,
    loading: loadingGetCupById,
    // FIXME: use error
    error,
    refetch,
  } = useGetCupByIdQuery({
    variables: {
      id: params['cupId'],
    },
    skip: !(isAuthenticated && params['cupId']),
  });

  const [cup, setCup] = useState({} as Cup);
  useEffect(() => {
    if (data?.cupById) setCup(Cup.fromCupsQuery([data.cupById])[0]);
  }, [data?.cupById]);

  const [editCupDetails, { loading: isLoadingEdit }] =
    useEditCupDetailsMutation();

  const [switchDefaultCupImage, { loading: isSwitchingDefaultCupImage }] =
    useSwitchDefaultCupImageMutation();

  const [setDefaultCupImage, { loading: isSettingDefaultCupImage }] =
    useSetDefaultCupImageMutation();

  const [insertCupImage, { loading: isInsertingCupImage }] =
    useInsertCupImageMutation();

  const [deleteCupImageById, { loading: isDeletingCupImage }] =
    useDeleteCupImageByIdMutation();

  const addImage = useCallback(
    async (image: File) => {
      if (image && cup) {
        // Step 1 - Upload Image
        const fileId = await nhost.storage
          .upload({
            file: image,
            // @Refactor: Use env var
            bucketId: 'cupImages',
          })
          .then((fileUploadRes) => {
            if (fileUploadRes.error) {
              alert(`error: ${fileUploadRes.error}`);
              return;
            }
            return fileUploadRes.fileMetadata.id;
          });

        // Step 2 - Add Cup Image
        await insertCupImage({
          variables: {
            cupImage: {
              cupId: cup.id,
              fileId,
            },
          },
        });

        await refetch();
      }
    },
    [cup, nhost.storage, insertCupImage, refetch]
  );

  const deleteCupImage = useCallback(
    async (cupImage: ICupImage) => {
      await deleteCupImageById({
        variables: {
          id: cupImage.id,
        },
      });
      await refetch();
    },
    [deleteCupImageById, refetch]
  );

  const changeDefaultCupImage = useCallback(
    async (newImage: ICupImage) => {
      if (!cup.images) return;
      if (cup.images.length)
        await switchDefaultCupImage({
          variables: {
            oldId: cup.images[0].id,
            newId: newImage.id,
          },
        });
      else
        setDefaultCupImage({
          variables: {
            id: newImage.id,
          },
        });
      await refetch();
    },
    [refetch, setDefaultCupImage, switchDefaultCupImage, cup.images]
  );

  return (
    (cup.id && (
      <Box p={10}>
        <Container maxW="100vw">
          <Heading as={'h1'} size={'4xl'}>
            Editar
          </Heading>

          <CupDetailsForm
            cup={cup as ICupDetails}
            onSubmitCupDetails={async (cDetails) => {
              await editCupDetails({
                variables: {
                  ...cDetails,
                  id: cup.id,
                },
              });
            }}
          />
          <Heading as={'h2'} size={'xl'} marginBottom={10}>
            Imagens
          </Heading>
          <CupImageList
            images={cup.images}
            onDeleteImage={(cupImage) => deleteCupImage(cupImage)}
            onAddImage={(image) => addImage(image)}
            onSetDefault={(image) => changeDefaultCupImage(image)}
          ></CupImageList>
          <Capture360 cupId={cup.id} />
        </Container>
      </Box>
    )) || <></>
  );
};

export default EditCupPage;

export const Route = '/cup/edit/:cupId';
