import { useEffect } from 'react';
import { useSignUpEmailPassword } from '@nhost/react';
import SignUpCard, { SignUpFormData } from '../components/auth/SignUpCard';
import { useNavigate } from 'react-router-dom';
import { Route as SignInRoute } from './SignIn';

const SignUp = () => {
  const {
    signUpEmailPassword,
    needsEmailVerification,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useSignUpEmailPassword();
  const navigate = useNavigate();
  const signUp = async ({
    email,
    password,
    firstName,
    lastName,
  }: SignUpFormData) => {
    await signUpEmailPassword(email, password, {
      displayName:
        (firstName.length && lastName.length && `${firstName} ${lastName}`) ||
        firstName ||
        lastName,
    });
  };

  useEffect(() => {
    if (needsEmailVerification || isSuccess) navigate(SignInRoute);
  }, [needsEmailVerification, isSuccess, navigate]);

  return (
    <SignUpCard
      onSubmit={signUp}
      isLoading={isLoading}
      error={(isError && ({ message: error?.message } as Error)) || undefined}
    />
  );
};

export default SignUp;

export const Route = '/sign-up';
