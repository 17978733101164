import {
  Box,
  Center,
  HStack,
  IconButton,
  Image,
  Stack,
  Tooltip,
} from '@chakra-ui/react';
import { MdImageNotSupported, MdOutlineImageAspectRatio } from 'react-icons/md';
import { CupImage, ICupImage } from './cup';
import Dropzone from '../dropzone';
import { IoIosImages } from 'react-icons/io';
import DefaultCupImageIcon from './DefaultCupImageIcon';
import { useNhostClient } from '@nhost/react';

interface CupImageListProps {
  images?: CupImage[];
  onDeleteImage?: (image: ICupImage) => void;
  onAddImage?: (acceptedImage: File) => void;
  onSetDefault?: (image: ICupImage) => void;
}
const CupImageList = ({
  images,
  onDeleteImage,
  onAddImage,
  onSetDefault,
}: CupImageListProps) => {
  const iconButtonSize = 60;
  const nhost = useNhostClient();

  return (
    <Stack
      direction={{ base: 'column', sm: 'row' }}
      verticalAlign={'middle'}
      shouldWrapChildren={true}
      wrap={'wrap'}
      justify={'flex-start'}
      spacing={0}
    >
      {onAddImage && (
        <Center
          height={'200px'}
          marginRight={{ base: '0', sm: '1rem' }}
          marginBottom={'1rem'}
          key={'image-list-dropzone'}
        >
          <Dropzone
            onFileAccepted={(acceptedImage) => onAddImage(acceptedImage)}
            iconSize={60}
          />
        </Center>
      )}
      {(images || []).map((image) => {
        return (
          <Box
            position={'relative'}
            key={image.id}
            marginRight={{ base: '0', sm: '1rem' }}
            marginBottom={'2rem'}
          >
            <Center height={'200px'}>
              {(image.fileId && (
                <Image
                  maxWidth={'350px'}
                  minWidth={'250px'}
                  zIndex="2"
                  maxHeight={'200px'}
                  borderRadius="lg"
                  src={`${nhost.storage.getPublicUrl({
                    fileId: image.fileId,
                  })}?w=350`}
                  alt="some good alt text"
                  objectFit="contain"
                />
              )) || <DefaultCupImageIcon size={'250px'} color="gray" />}
            </Center>
            {(onSetDefault || onDeleteImage) && (
              <Box
                position="absolute"
                left={`calc(50% - ${Math.round(iconButtonSize + 8)}px)`}
                top={`calc(50% - ${Math.round(iconButtonSize / 2)}px)`}
                zIndex={2}
              >
                <HStack
                  spacing={'16px'}
                  justify="center"
                  width={`${Math.round(iconButtonSize * 2 + 16)}px`}
                >
                  {onSetDefault && (
                    <Tooltip
                      label={
                        (image.isDefault &&
                          'This Cup image is the cover image') ||
                        'Set Cup Image as Cover Image'
                      }
                      fontSize="md"
                    >
                      <IconButton
                        aria-label={
                          (image.isDefault &&
                            'This Cup image is the cover image') ||
                          'Set Cup Image as Cover Image'
                        }
                        variant="outline"
                        color="blackAlpha.700"
                        colorScheme="blackAlpha"
                        onClick={() => onSetDefault(image)}
                        size={`${iconButtonSize}px`}
                        isDisabled={image.isDefault}
                      >
                        {(image.isDefault && (
                          <MdOutlineImageAspectRatio
                            size={`${iconButtonSize}px`}
                          />
                        )) || <IoIosImages size={`${iconButtonSize}px`} />}
                      </IconButton>
                    </Tooltip>
                  )}
                  {onDeleteImage && (
                    <Tooltip label="Delete Cup Image" fontSize="md">
                      <IconButton
                        aria-label="Delete Cup Image"
                        variant="outline"
                        color="blackAlpha.700"
                        colorScheme="blackAlpha"
                        onClick={() => onDeleteImage(image)}
                        size={`${iconButtonSize}px`}
                      >
                        <MdImageNotSupported size={`${iconButtonSize}px`} />
                      </IconButton>
                    </Tooltip>
                  )}
                </HStack>
              </Box>
            )}
          </Box>
        );
      })}
    </Stack>
  );
};

export default CupImageList;
