import React, { PropsWithChildren, ReactNode, useEffect } from 'react';
import { Box, IconButton, useBreakpointValue } from '@chakra-ui/react';
// Here we have used react-icons package for the icons
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi';
// And react-slick as our Carousel Lib
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ImageCarousel.css';

export interface IImage {
  id: string;
  url: string;
}

export interface ImageCarouselProps {
  imageHeight?: string;
  images?: IImage[];
  showArrows?: boolean;
  showDots?: boolean;
}

const ImageCarousel: React.FC<PropsWithChildren<ImageCarouselProps>> = ({
  imageHeight,
  images,
  showArrows = true,
  showDots = true,
  children,
}) => {
  // As we have used custom buttons, we need a reference variable to
  // change the state
  const [slider, setSlider] = React.useState<Slider | null>(null);

  // These are the breakpoints which changes the position of the
  // buttons as the screen size changes
  const top = '50%';
  const side = '10px';

  // Settings for the slider
  const settings = {
    dots: showDots,
    arrows: false,
    fade: true,
    infinite: true,
    autoplay: false,
    speed: 500,
    appendDots: (dots: ReactNode) => {
      return <Box bottom="25px">{dots}</Box>;
    },
  };

  return (
    <Box position={'relative'} height={'100%'}>
      {/* Left Icon */}
      {showArrows && (
        <IconButton
          aria-label="left-arrow"
          colorScheme="blackAlpha"
          borderRadius="full"
          position="absolute"
          left={side}
          top={top}
          transform={'translate(0%, -50%)'}
          zIndex={2}
          onClick={() => slider?.slickPrev()}
        >
          <BiLeftArrowAlt />
        </IconButton>
      )}
      {/* Right Icon */}
      {showArrows && (
        <IconButton
          aria-label="right-arrow"
          colorScheme="blackAlpha"
          borderRadius="full"
          position="absolute"
          right={side}
          top={top}
          transform={'translate(0%, -50%)'}
          zIndex={2}
          onClick={() => slider?.slickNext()}
        >
          <BiRightArrowAlt />
        </IconButton>
      )}
      {/* Slider */}
      <Slider
        {...settings}
        className={'image-carousel-slider'}
        ref={(s) => setSlider(s)}
      >
        {children ||
          images?.map(({ id, url }) => (
            <Box
              key={id}
              height={imageHeight}
              position="relative"
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              backgroundSize="contain"
              backgroundImage={`url(${url})`}
            />
          ))}
      </Slider>
    </Box>
  );
};

export default ImageCarousel;
