import { Box } from '@chakra-ui/react';
import { ReactNode } from 'react';
import ChavenasNav from './NavDrawer';

const PageLayout = ({ children }: { children: ReactNode }) => {
  return (
    <Box>
      <ChavenasNav />
      {children}
    </Box>
  );
};

export default PageLayout;
