import { ListCupsQuery } from '../../utils/__generated__/graphql';

export interface ICupImage {
  id: string;
  isDefault: boolean;
  fileId?: string;
}

export type ICupDetails = {
  brand: string;
  extRef?: string;
  description?: string;
};
export interface ICup extends ICupDetails {
  id: string;
  images: ICupImage[];
}

export enum Versions {
  small = 'small',
  medium = 'medium',
  large = 'large',
  raw = 'raw',
}

export class CupImage implements ICupImage {
  constructor(
    public id: string,
    public isDefault: boolean,
    public fileId?: string
  ) {
    this.id = id;
    this.isDefault = isDefault;
    this.fileId = fileId;
  }

  static versionsOrder = [
    Versions.small,
    Versions.medium,
    Versions.large,
    Versions.raw,
  ];

  static fromCupImagesQuery(cupImages: ListCupsQuery['cups'][0]['images']) {
    return cupImages.map(
      (cupImage) =>
        new CupImage(cupImage.id, cupImage.isDefault, cupImage.fileId)
    );
  }
}
export class Cup implements ICup {
  constructor(
    public id: string,
    public brand: string,
    public images: CupImage[],
    public description?: string,
    public extRef?: string
  ) {
    this.id = id;
    this.brand = brand;
    this.images = images;
    this.description = description;
    this.extRef = extRef;
  }

  static fromCupsQuery(cups: ListCupsQuery['cups']) {
    return cups.map(
      (cup) =>
        new Cup(
          cup.id,
          cup.brand,
          CupImage.fromCupImagesQuery(cup.images),
          cup.description || undefined,
          cup.extRef || undefined
        )
    );
  }
}
