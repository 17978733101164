import { Routes, Route, Link } from 'react-router-dom';

import SignInPage, { Route as SignInRoute } from './pages/SignIn';
import SignUpPage, { Route as SignUpRoute } from './pages/SignUp';
import SignOutPage, { Route as SignOutRoute } from './pages/SignOut';
import CupsPage, { Route as CupsRoute } from './pages/Cups';
import { Route as EditCupRoute } from './pages/EditCup';

import AuthGate from './components/auth/AuthGate';
import PageLayout from './layouts/PageLayout';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <AuthGate signInUrl={SignInRoute}>
            <PageLayout>
              <CupsPage />
            </PageLayout>
          </AuthGate>
        }
      />
      <Route path={`${SignInRoute}/*`} element={<SignInPage />} />
      <Route path={`${SignUpRoute}/*`} element={<SignUpPage />} />
      <Route
        path={SignOutRoute}
        element={
          <AuthGate signInUrl={SignInRoute}>
            <SignOutPage />
          </AuthGate>
        }
      />
      <Route
        path={CupsRoute}
        element={
          <AuthGate signInUrl={SignInRoute}>
            <PageLayout>
              <CupsPage />
            </PageLayout>
          </AuthGate>
        }
      />
      <Route
        path={EditCupRoute}
        caseSensitive={false}
        element={
          <AuthGate signInUrl={SignInRoute}>
            <PageLayout>
              <CupsPage />
            </PageLayout>
          </AuthGate>
        }
      />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default AppRoutes;
