import {
  useColorModeValue,
  useBreakpointValue,
  ChakraProps,
} from '@chakra-ui/react';
import { Box, Badge } from '@chakra-ui/layout';
import ContextMenu, { IContextMenu } from './ContextMenu';
import ImageCarousel from '../../image_carousel/ImageCarousel';
import CupDetails from './CupDetails';
import { Cup, CupImage, Versions } from '../cup';
import DefaultCupImageIcon from '../DefaultCupImageIcon';
import { useNhostClient } from '@nhost/react';

type CupCardProps = ChakraProps & {
  imageHeight?: string;
  data: {
    isNew?: boolean;
    cup: Cup;
  };
  contextMenu: IContextMenu | null;
  onClick?: (cup: Cup, cupImage?: CupImage) => void;
};

function CupCard({
  imageHeight = '290px',
  data: { isNew = false, cup },
  contextMenu,
  onClick,
  ...styleProps
}: CupCardProps) {
  const paddingY = useBreakpointValue({
    base: '8px',
    sm: '16px',
  });
  const nhost = useNhostClient();
  const { images, brand, description } = cup;
  return (
    <Box
      bg={useColorModeValue('white', 'gray.800')}
      borderWidth="1px"
      maxW={'100vw'}
      shadow="lg"
      rounded="lg"
      overflow={'hidden'}
      {...styleProps}
    >
      <Box position="relative">
        {contextMenu && (
          <ContextMenu
            position="absolute"
            right={`16px`}
            top={`16px`}
            zIndex={5}
            menu={contextMenu}
          />
        )}

        <Box py={paddingY} bgColor={'blackAlpha.400'}>
          <ImageCarousel showDots={false} showArrows={!!images.length}>
            {images.length > 0 ? (
              images.map((image, index) => {
                if (image?.fileId) {
                  const fileId = image.fileId;
                  return (
                    <Box
                      key={fileId}
                      height={imageHeight}
                      position="relative"
                      backgroundPosition="center"
                      backgroundRepeat="no-repeat"
                      backgroundSize="contain"
                      backgroundImage={`url(${nhost.storage.getPublicUrl({
                        fileId: fileId,
                      })}?w=800)`}
                      onClick={() => onClick && onClick(cup, image)}
                    />
                  );
                } else {
                  return (
                    <Box
                      key={`default-image-${index}`}
                      height={imageHeight}
                      position="relative"
                    >
                      <DefaultCupImageIcon size="100%" />
                    </Box>
                  );
                }
              })
            ) : (
              <Box
                height={imageHeight}
                position="relative"
                key="empty-list-icon"
              >
                <DefaultCupImageIcon size="100%" />
              </Box>
            )}
          </ImageCarousel>
        </Box>

        <Box p="6">
          <Box display="flex" alignItems="baseline">
            {isNew && (
              <Badge rounded="full" px="2" fontSize="0.8em" colorScheme="red">
                New
              </Badge>
            )}
          </Box>
          <CupDetails brand={brand} description={description} />
        </Box>
      </Box>
    </Box>
  );
}

export default CupCard;
