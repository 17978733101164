import {
  Flex,
  IconButton,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  VStack,
  DrawerFooter,
  Text,
  Heading,
} from '@chakra-ui/react';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import { Route as CupsPageRoute } from '../pages/Cups';
import { Route as SignOutPageRoute } from '../pages/SignOut';
import { useUserDisplayName } from '@nhost/react';
import DefaultCupImageIcon from '../components/cups/DefaultCupImageIcon';

export default function TresorNav() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const Links = [{ label: 'Chavenas', link: CupsPageRoute }];

  const userDisplayName = useUserDisplayName();

  return (
    <>
      <IconButton
        size={'md'}
        icon={<HamburgerIcon />}
        aria-label={'Open Menu'}
        onClick={onOpen}
        pos={'fixed'}
        top={10}
        left={2}
      />
      <Drawer placement={'left'} onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerHeader borderBottomWidth="1px">
            <IconButton
              variant={'none'}
              size={'md'}
              icon={<CloseIcon />}
              aria-label={'Close Menu'}
              position={'absolute'}
              right={0}
              top={0}
              onClick={onClose}
            />
            <VStack alignItems={'flex-start'} spacing={10} pt={20}>
              <DefaultCupImageIcon size={'full'} />
              <Flex width={'100%'}>
                <Text>Chavenas - v{process.env.REACT_APP_VERSION}</Text>
              </Flex>
              <Text>{userDisplayName}</Text>
            </VStack>
          </DrawerHeader>
          <DrawerBody>
            <Heading as={'h5'} size={'sm'} mt={10}>
              Navigation
            </Heading>
            <VStack mt={8} mb={10} spacing={4} alignItems={'flex-start'}>
              {Links.map(({ link, label }) => (
                <Link key={link} to={link}>
                  {label}
                </Link>
              ))}
            </VStack>
          </DrawerBody>
          <DrawerFooter>
            <Link key={'logout-link'} to={SignOutPageRoute}>
              Sign out
            </Link>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
