import { Button } from '@chakra-ui/react';
import React, { useState, useEffect, useCallback } from 'react';
import { io } from 'socket.io-client';

const CAPTURE_HOST = 'https://raspberrypi.local:5000';
const socket = io(CAPTURE_HOST);
const Capture360 = ({ cupId }: { cupId: string }) => {
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [lastPong, setLastPong] = useState<string | null>(null);

  useEffect(() => {
    socket.on('connect', () => {
      setIsConnected(true);
    });

    socket.on('disconnect', () => {
      setIsConnected(false);
    });

    socket.on('pong', () => {
      setLastPong(new Date().toISOString());
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('pong');
    };
  }, []);

  const capture360 = useCallback(() => {
    socket.emit('capture_360_deg', { cupId });
  }, [cupId]);

  return (
    <>
      <Button onClick={() => capture360()}>Capture 360 Deg</Button>
      <img src={`${CAPTURE_HOST}/video_feed`} width="400px"></img>
    </>
  );
};

export default Capture360;
