import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useAuthenticationStatus } from '@nhost/react';

import SignInCard from '../components/auth/SignInCard';

const SignIn = () => {
  const { isAuthenticated } = useAuthenticationStatus();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated) {
      const prevState = location.state as Location;
      navigate(prevState || '/', {
        replace: true,
      });
    }
  }, [isAuthenticated, location, navigate]);

  return <SignInCard />;
};

export default SignIn;

export const Route = '/sign-in';
