import { Box, BoxProps, Flex } from '@chakra-ui/layout';
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/menu';
import { Button } from '@chakra-ui/button';
import { BsThreeDots } from 'react-icons/bs';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import React, { LegacyRef } from 'react';

export type ContextMenuItem = {
  label: string;
  onClick: () => void;
};

export type IContextMenu = {
  editCup: ContextMenuItem;
  deleteCup: ContextMenuItem;
};

interface ContextMenuProps {
  menu: IContextMenu;
}

const ContextMenu = ({ menu, ...boxProps }: BoxProps & ContextMenuProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>();

  return (
    <Box {...boxProps}>
      <Flex alignItems={'center'}>
        <Menu>
          <MenuButton
            as={Button}
            rounded={'full'}
            variant={'link'}
            cursor={'pointer'}
            minW={0}
          >
            <BsThreeDots size={32} />
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => menu.editCup.onClick()}>
              {menu.editCup.label}
            </MenuItem>
            <MenuItem onClick={() => onOpen()}>{menu.deleteCup.label}</MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef as any}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Cup
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure? You can't undo this action afterwards.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef as LegacyRef<HTMLButtonElement>}
                onClick={onClose}
              >
                Cancel
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  menu.deleteCup.onClick();
                  onClose();
                }}
                ml={3}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default ContextMenu;
