import { Box, Flex } from '@chakra-ui/layout';

interface CupDetailsProps {
  brand: string;
  description?: string;
}

const CupDetails: React.FC<CupDetailsProps> = ({ brand, description }) => {
  return (
    <Flex
      flexDir={'column'}
      justifyContent="space-between"
      alignContent="center"
    >
      <Box fontSize="2xl" fontWeight="semibold" as="h4" lineHeight="tight">
        {brand}
      </Box>
      <Box
        fontSize="xs"
        as="p"
        display={'-webkit-box'}
        overflow={'hidden'}
        style={{
          ['WebkitBoxOrient' as any]: 'vertical',
          ['WebkitLineClamp' as any]: 3,
        }}
      >
        {description}
      </Box>
    </Flex>
  );
};

export default CupDetails;
