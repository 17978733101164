import { Input } from '@chakra-ui/react';
import { useState } from 'react';
import useDebouncedEffect from '../../utils/hooks/useDebouncedEffect';

interface DebouncedSearchInputProps {
  handleDebouncedValueChange: (value: string) => void;
  placeholder: string;
}

const DebouncedSearchInput = (props: DebouncedSearchInputProps) => {
  const [brandSearchValue, setBrandSearchValue] = useState('');
  // TODO: Use appropriate event type instead of "any"
  const handleBrandSearchValueChange = (event: any) =>
    setBrandSearchValue(event.target.value);

  useDebouncedEffect(
    () => props.handleDebouncedValueChange(brandSearchValue),
    500,
    [brandSearchValue]
  );

  return (
    <Input
      value={brandSearchValue}
      placeholder={props.placeholder || 'Search Input value'}
      onChange={handleBrandSearchValueChange}
    />
  );
};

export default DebouncedSearchInput;
