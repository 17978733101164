import { AddIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormLabel,
  Input,
  Stack,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useState, useCallback } from 'react';
import BrandSelect from './BrandSelect';

interface AddCupDrawerProps {
  isAddingCup: boolean;
  onSubmit: (brand?: string) => Promise<void>;
}

const AddCupDrawer: React.FC<AddCupDrawerProps> = ({
  onSubmit,
  isAddingCup = false,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const firstField = React.useRef<HTMLSelectElement>(null);
  const [brand, setBrand] = useState<string | undefined>();

  const createCup = useCallback(
    () => onSubmit && onSubmit(brand),
    [brand, onSubmit]
  );

  return (
    <>
      <Button leftIcon={<AddIcon />} onClick={onOpen}>
        Add
      </Button>
      <Drawer
        isOpen={isOpen}
        placement="right"
        initialFocusRef={firstField}
        onClose={onClose}
        closeOnOverlayClick={false}
        closeOnEsc={false}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">Create a new Cup</DrawerHeader>

          <DrawerBody>
            <Stack spacing="24px">
              <Box>
                <FormLabel htmlFor="brand">Brand</FormLabel>
                <BrandSelect
                  ref={firstField}
                  id="brand"
                  placeholder="Please select a brand"
                  onChange={(event) => setBrand(event.target.value)}
                />
              </Box>
            </Stack>
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px">
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button onClick={createCup} isLoading={isAddingCup}>
              Submit
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default AddCupDrawer;
