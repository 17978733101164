import { useNavigate } from 'react-router-dom';
import { useAuthenticationStatus } from '@nhost/react';
import { PropsWithChildren, useEffect } from 'react';

type AuthGateProps = {
  signInUrl?: string;
};

const AuthGate: React.FC<PropsWithChildren<AuthGateProps>> = ({
  signInUrl,
  children,
}) => {
  const { isLoading, isAuthenticated } = useAuthenticationStatus();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isAuthenticated && signInUrl && !isLoading) {
      navigate(signInUrl, { replace: true });
    }
  }, [isLoading, signInUrl, isAuthenticated, navigate]);

  return <>{children}</>;
};

export default AuthGate;
