import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import BrandSelect from './BrandSelect';
import { ICupDetails } from './cup';

interface CupDetailsFormProps {
  cup: ICupDetails;
  onSubmitCupDetails?: (cup: ICupDetails) => Promise<void>;
}

const CupDetailsForm: React.FC<CupDetailsFormProps> = ({
  cup,
  onSubmitCupDetails = () => {
    return;
  },
}) => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting, isDirty, isValid, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      brand: cup.brand,
      description: cup.description || '',
    },
  });

  useEffect(() => {
    reset({ brand: cup.brand, description: cup.description || '' });
  }, [cup, isSubmitSuccessful, reset]);

  return (
    <form
      onSubmit={handleSubmit((values) =>
        onSubmitCupDetails(values as ICupDetails)
      )}
    >
      <FormControl isInvalid={!!errors.brand}>
        <VStack paddingY={10} alignItems={'start'} maxW="lg">
          <FormLabel htmlFor="brand">Marca</FormLabel>
          <BrandSelect
            id="brand"
            maxW="2xs"
            {...register('brand', { required: 'This is required' })}
          />
          <FormErrorMessage>
            {errors.brand && errors.brand.message}
          </FormErrorMessage>

          <FormLabel htmlFor="description">Descrição</FormLabel>
          <HStack width={'100%'}>
            <Textarea
              size={'sm'}
              {...register('description')}
              placeholder="Descrição"
            />

            <Button
              mt={4}
              colorScheme="teal"
              isLoading={isSubmitting}
              isDisabled={!isDirty || !isValid}
              type="submit"
              alignSelf={'flex-end'}
            >
              Guardar
            </Button>
          </HStack>
        </VStack>
      </FormControl>
    </form>
  );
};

export default CupDetailsForm;
